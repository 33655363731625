:root {
  /* main colors*/
  --accent-color: #ff4e4e;
  
  /* fonts */
  --main-font: "Inter", sans-serif;
  --secondary-font: "Coolvetica", sans-serif;

  /* text */
  --main-text-color: rgb(31, 31, 31);
  --hover-text-color: rgb(122, 122, 122);

  /* backgroung */
  --main-background-color: #bdbdbd;
}
